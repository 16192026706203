import {
  IdJsonSchema,
  MultiLanguageJsonSchema,
  deserializeDate,
  serializeDate,
} from '@api';
import { LinksJson } from '@api/hateos';
import { environment } from '@env/evd/environment';
import { ChargingSession } from '@model/evd/charging-sessions';
import { z } from 'zod';

export const ChargingSessionJsonSchema = z.object({
  chargerName: z.string(),
  consumptionWh: z.number(),
  contractName: MultiLanguageJsonSchema,
  currencyCode: z.string(),
  durationSeconds: z.number(),
  evseId: IdJsonSchema,
  locationName: z.string().optional(),
  sessionEndDate: z.string(),
  sessionStartDate: z.string(),
  startIdTag: z.string(),
  totalBilled: z.number(),
  transactionId: IdJsonSchema,
  _links: LinksJson(environment.urls.api).optional(),
});
export type ChargingSessionJson = z.infer<typeof ChargingSessionJsonSchema>;

export function serializeChargingSession(
  chargingSession: ChargingSession,
): ChargingSessionJson {
  return {
    ...chargingSession,
    transactionId: chargingSession.id,
    sessionStartDate: serializeDate(chargingSession.sessionStartDate),
    sessionEndDate: serializeDate(chargingSession.sessionEndDate),
  };
}

export function deserializeChargingSession(
  chargingSession: ChargingSessionJson,
): ChargingSession {
  return {
    ...chargingSession,
    id: chargingSession.transactionId,
    sessionStartDate: deserializeDate(chargingSession.sessionStartDate),
    sessionEndDate: deserializeDate(chargingSession.sessionEndDate),
  };
}
