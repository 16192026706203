import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link, LinkMethod } from '@model';
import { ChargingSession } from '@model/evd/charging-sessions';
import { z } from 'zod';

import { AbstractEvdApiService } from '../evd-api.service';
import {
  ChargingSessionJson,
  ChargingSessionJsonSchema,
  deserializeChargingSession,
  serializeChargingSession,
} from './io/charging-session.io';

@Injectable()
export class ChargingSessionsApiService extends AbstractEvdApiService<
  ChargingSession,
  ChargingSessionJson
> {
  public constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  protected serialize(item: ChargingSession): ChargingSessionJson {
    return serializeChargingSession(item);
  }

  protected deserialize(item: ChargingSessionJson): ChargingSession {
    return deserializeChargingSession(item);
  }

  protected getLinksLink(): Link {
    return {
      href: '/driver/charging-sessions/.links',
      method: LinkMethod.GET,
      templated: false,
    };
  }

  protected getJsonSchema(): z.Schema<ChargingSessionJson> {
    return ChargingSessionJsonSchema;
  }

  protected getListJsonSchema(): z.Schema<ChargingSessionJson> {
    return this.getJsonSchema();
  }

  protected listDeserialize(item: ChargingSessionJson): ChargingSession {
    return this.deserialize(item);
  }
}
